// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-snippets-jsx": () => import("./../../../src/pages/snippets.jsx" /* webpackChunkName: "component---src-pages-snippets-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-snippet-jsx": () => import("./../../../src/templates/snippet.jsx" /* webpackChunkName: "component---src-templates-snippet-jsx" */)
}

